export const FETCH_JUGADORES = 'FETCH_JUGADORES'
export const FETCH_RANKING = 'FETCH_RANKING'
export const POST_JUGADOR = 'POST_JUGADOR'
export const DELETE_JUGADORES = 'DELETE_JUGADORES'
export const CREATE_COPA ='CREATE_COPA'
export const FETCH_COPAS = 'FETCH_COPAS'
export const PUT_JUGADORES ='PUT_JUGADORES'
export const PUT_PUNTOSJUGADORES = 'PUT_PUNTOSJUGADORES'
export const PUT_RANKING = "PUT_RANKING" 
export const COPA_FINALIZADA = "COPA_FINALIZADA"
export const FETCH_ALIENS = "FETCH_ALIENS"
export const PUT_CAMPAÑA = "PUT_CAMPAÑA"