import React from 'react'
import s from './Copas.module.css'

import { CopaCard } from './CopaCard';

export const Copas = () => {


  return (
    <div className={s.elemento}>
       <CopaCard/>       
      
    </div>
  )
}
